<template>
  <div class="slider">
    <img @click="visibleSlider = true; count = 0" class="slider__main-image" :src="getUserAvatar(data.images[0], true)" alt="">
    <div class="slider__wrapper">
      <img v-if="visibleArrow" @click="prev" class="slider__wrapper-prev" src="@/assets/svg/icon-slider.svg" alt="">
      <img v-if="visibleArrow" @click="next" class="slider__wrapper-next" src="@/assets/svg/icon-slider.svg" alt="">
      <div :style="translate" class="slider__wrapper-items">
        <img
            @click="visibleSlider = true; count = key" class="slider-item"
            v-for="(photo, key) in data.images"
            :key="key"
            :src="getUserAvatar(photo, true)"
            alt="">
      </div>
    </div>
    <v-slider v-model="visibleSlider" :data="data" :count="count" />
  </div>
</template>

<script>
import { getUserAvatar } from "@/utils/user";
export default {
  name: "Slider",
  components: {
    VSlider: () => import('@/components/common/VSlider'),
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      index: 0,
      count: 0,
      visibleSlider: false,
      getUserAvatar
    }
  },

  computed: {
    translate() {
      if(window.innerWidth > 768) {
        return {transform: `translateY(-${this.index * 122}px)`}
      } else {
        return {transform: `translateX(-${this.index * 176}px)`}
      }
    },
    visibleArrow() {
      if(window.innerWidth > 768) {
        return this.data.images.length > 3
      } else {
        return this.data.images.length > 2
      }
    }
  },

  watch: {
    visibleSlider(val) {
      const viewRequests = document.querySelector('.view-requests')
      if(val) {
        viewRequests.style.maxHeight='100vh'
        viewRequests.style.minHeight='100%'
      } else {
        viewRequests.style.maxHeight=''
        viewRequests.style.minHeight='100%'
      }
    }
  },

  methods: {
    prev() {
      if(this.index > 0) {
        this.index --
      }
    },
    next() {
      if(window.innerWidth > 768) {
        if(this.data.images.length - this.index > 3) {
          this.index ++
        }
      }
      else {
        if(this.data.images.length - this.index > 2) {
          this.index ++
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .slider {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    gap: 10px;
    transition: .5s;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__main-image {
      width: 100%;
      max-width: 458px;
      max-height: 356px;
      border-radius: 10px;
      object-fit: cover;
      cursor: pointer;

      @media (max-width: 1200px) {
        max-width: 400px;
      }

      @media (max-width: 768px) {
        max-height: 230px;
      }
    }

    &__wrapper {
      width: 100%;
      max-width: 162px;
      max-height: 356px;
      overflow-y: hidden;

      @media (max-width: 768px) {
        overflow-y: auto;
        overflow-x: hidden;
        max-width: 343px;
      }

      &-prev {
        position: absolute;
        top: -24px;
        right: 55px;
        z-index: 1;
        cursor: pointer;

        @media (max-width: 768px) {
          top: auto;
          bottom: 29px;
          transform: rotate(-90deg);
          left: -15px;
        }
      }

      &-next {
        position: absolute;
        z-index: 1;
        transform: rotate(180deg);
        right: 55px;
        cursor: pointer;
        bottom: -24px;

        @media (max-width: 768px) {
          transform: rotate(90deg);
          bottom: 29px;
          right: -15px;
        }
      }

      &-items {
        display: flex;
        flex-direction: column;
        gap: 10px;
        transition: .3s;
        max-height: 356px;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: row;
          max-width: 100%
        }

        .slider-item {
          width: 162px;
          height: 112px;
          border-radius: 10px;
          object-fit: cover;
          cursor: pointer;

          @media (max-width: 768px) {
            max-width: 100%;
            min-width: 166px;
            height: 110px;
          }
        }
      }
    }
  }
</style>